:root {
  --landingBackgroundColor: #111111;
  --landingTextColor: #ffffff;

  --fontLight: 300;
  --fontRegular: 400;
  --fontSemiBold: 600;
  --fontBold: 700;

  --colorBackgroundComingSoon: #f9f9f9;
  --colorFooterTextComingSoon: #cacaca;
  --colorComingSoonHighlight: #cd0000;
  --cologComingSoonBackgroundBlendColor: #d0d0d0;
  --colorComingSoonHighlightBorder: #a0a0a0;
  --colorComingSoonButtonText: #f6f5f5;
  --colorComingSoonInvitationStatusBorder: #d8d8d8;

  --colorBlack: #000;
  --colorWhite: #fff;
  --colorRed: #f00;

  --colorPrimary: #cd0000;

  --zIndex1: 10;
  --zIndex2: 20;
  --zIndex3: 30;
  --zIndex4: 40;
  --zIndex5: 50;
}
