.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--landingBackgroundColor);

  .header {
    display: flex;
    align-items: center;
    height: 70px;
    padding: 20px 20px 20px 14px;

    .headerLogo {
      width: 36px;
      height: 36px;
    }

    .headerLogoText {
      font-size: 28px;
      font-weight: var(--fontBold);
      color: var(--landingTextColor);
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .bannerSection {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 0;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .bannerHint {
        color: var(--landingTextColor);
        font-size: 20px;
      }

      .bannerText {
        color: var(--landingTextColor);
        font-size: 35px;
        font-weight: var(--fontBold);
        text-transform: uppercase;
        padding-right: 20%;
      }
    }

    .objectivesSection {
      display: flex;
      padding: 50px 20px 0;

      .objectivesText {
        color: var(--landingTextColor);
        font-size: 25px;
        font-weight: var(--fontBold);
        padding-right: 10%;
      }
    }

    .highlightsSection {
      display: flex;
      flex-direction: column;
      padding: 100px 20px 0;

      .highlight {
        display: flex;
        flex-direction: column;

        &:not(:first-child) {
          margin-top: 50px;
        }

        .highlightImage {
        }

        .highlightText {
          margin-top: 20px;

          color: var(--landingTextColor);
          font-size: 25px;
          font-weight: var(--fontSemiBold);
        }
      }
    }
  }

  .comingSoonSection {
    display: inline;
    margin-top: 150px;
    padding: 25px 20px;

    @mixin comingSoonText {
      color: var(--landingTextColor);
      font-size: 28px;
      font-weight: var(--fontBold);
    }

    .comingSoonText {
      @include comingSoonText;
    }

    .comingSoonText2 {
      @include comingSoonText;
      margin-left: 20px;

      animation: blink 0.85s steps(5, start) infinite;
      animation-timing-function: steps(2, start);
    }

    @keyframes blink {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  .footer {
    display: flex;

    margin-top: 150px;
    padding: 25px 20px;

    .copyrightNotice {
      font-size: 12px;
      color: #808080;
    }
  }
}
