.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--landingBackgroundColor);

  .header {
    display: flex;
    align-items: center;
    padding: 40px;

    .headerLogo {
      width: 40px;
      height: 40px;
    }

    .headerLogoText {
      font-size: 32px;
      font-weight: var(--fontBold);
      color: var(--landingTextColor);
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .bannerSection {
      display: flex;
      flex-direction: column;
      padding: 20px 40px 0;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .bannerHint {
        color: var(--landingTextColor);
        font-size: 20px;
        font-weight: var(--fontBold);
      }

      .bannerText {
        color: var(--landingTextColor);
        font-size: 80px;
        text-transform: uppercase;
        padding-right: 40%;
      }
    }

    .objectivesSection {
      display: flex;
      padding: 100px 40px 0;

      .objectivesText {
        color: var(--landingTextColor);
        font-size: 50px;
        padding-left: 40%;
      }
    }

    .highlightsSection {
      display: flex;
      flex-direction: column;
      padding: 150px 40px 0;

      .highlight {
        display: flex;

        &:not(:first-child) {
          margin-top: 200px;
        }

        .highlightImage {
          width: 60%;
        }

        .highlightText {
          width: 40%;
          color: var(--landingTextColor);
          font-size: 38px;

          &:first-child {
            padding-right: 40px;
          }

          &:not(:first-child) {
            padding-left: 40px;
          }
        }
      }
    }

    .comingSoonSection {
      display: flex;
      justify-content: center;
      margin-top: 150px;
      padding: 50px 40px;

      @mixin comingSoonText {
        color: var(--landingTextColor);
        font-size: 40px;
        font-weight: var(--fontBold);
      }

      .comingSoonText {
        @include comingSoonText;
      }

      .comingSoonText2 {
        @include comingSoonText;
        margin-left: 20px;

        animation: blink 0.85s steps(5, start) infinite;
        animation-timing-function: steps(2, start);
      }

      @keyframes blink {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 150px;
    padding: 25px 40px;

    .copyrightNotice {
      align-self: flex-end;
      font-size: 14px;
      color: #808080;
    }
  }
}
