.rootContainer {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.primaryContainer {
  flex-grow: 1;
}
